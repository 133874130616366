































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import ACTT from '@/models/ACTT';
import WorkSession from '@/models/WorkSession';
import i18n from '@/i18n';
import Location from '@/models/Location';
import UserInitialsConfig from '@/misc/UserInitialsConfig';

@Component({
  components: {
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    SignatureComponent: () => import(
        /* webpackChunkName: "SignatureComponent" */
        '@/components/shared/Signature.component.vue'),
    SessionACTTDetailsComponent: () => import(
        /* webpackChunkName: "SessionACTTDetailsComponent" */
        '@/components/job/sessionACTT/SessionACTTDetails.component.vue'),
    ActtComponent: () => import(
        /* webpackChunkName: "ActtItemComponent" */
        '@/components/actt/Actt.component.vue'),
  },
})
export default class TimeTrackingDetailComponent extends Vue {
  @Prop({default: undefined})
  private data!: WorkSession;

  /**
   * Configuration for User Initials
   */
  public config: UserInitialsConfig = {
    showFullName: false,
  };
  /**
   * Check if the worksession has a signature
   */
  get hasSignature(): boolean|null {
    return this.data.employeeSign && this.data.employeeSign.pathData
        && this.data.employeeSign.pathData.data !== null;
  }

  /**
   * Get Location of Worksession
   */
  get location(): Location | null {
    if (!this.data.location) {
      return null;
    }

    return this.data.location;
  }

  /**
   * get current locale of i18n
   */
  get locale(): string {
    return i18n.locale;
  }
}
